import { createContext, useContext, useState } from "react";

const bigContext = createContext();

export const BigContextProvider = ({ children }) => {
    const [bigContextState, setBigContextState] = useState();

    return (
        <bigContext.Provider value={{ bigContextState, setBigContextState }}>
            {children}
        </bigContext.Provider>
    )
};

export const useBigContext = ()=> {
    const context = useContext(bigContext);
    if (!context) {
        throw new Error('useBigContext must be used within a BigProvider');
    }
    return context;
} 